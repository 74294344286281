import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { ShowSnackBar } from "./app";
import { getUser, updateIsLoading } from "../slices/auth";
import { allPropertyList } from "./app";

const initialState = {
  submit: {
    clicked: false,
  },

  tenantProperty: {
    agentName: null,
    agentPhone: null,
    relationship: null,
    refreeName: null,
    refreeEmail: null,
    knownYears: null,
    refreePhone: null,
    user_id: null,
  },
  currentLivingArrangements: [],
  previousLivingArrangements: [],
  isEditLivingArrangement: false,
  loader: false,
  selectedArrangement: null,
  browsePropertyList: [],
  watchList: [],
  error: null,
  property_type: ["all"],
  availability: "",
  propReq: ["all"],
  bed_room: "all",
  bathroom: "all",
  downStepCount: 0,
  sideStepCount: 0,
  prefrenceStepCount: 0,
  car_spaces: "all",
  greaterThen: 0,
  lessThen: 10000,
  searchPrefrence: {},
  totalWathlist: 0,
  filterReset: false,
};

const slice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    tenantLandlord(state, action) {
      if (action?.payload?.agentName) {
        state.tenantProperty.agentName = action.payload.agentName;
      }
      if (action.payload.agentPhone) {
        state.tenantProperty.agentPhone = action.payload.agentPhone;
      }
      if (action.payload.relationship) {
        state.tenantProperty.relationship = action.payload.relationship;
      }
      if (action.payload.refreeName) {
        state.tenantProperty.refreeName = action.payload.refreeName;
      }
      if (action.payload.refreeEmail) {
        state.tenantProperty.refreeEmail = action.payload.refreeEmail;
      }
      if (action.payload.knownYears) {
        state.tenantProperty.knownYears = action.payload.knownYears;
      }
      if (action.payload.refreePhone) {
        state.tenantProperty.refreePhone = action.payload.refreePhone;
      }
    },
    tenantloader(state, action) {
      state.loader = action.payload.loader;
    },
    tenantInfo(state, action) {
      if (action.payload?.user_id) {
        state.user_id = action.payload.user_id;
      } else {
        state.user = action.payload.user;
      }
    },
    allPropertyListTenant(state, action) {
      state.browsePropertyList = action.payload.list;
    },
    getWatchlist(state, action) {
      state.watchList = action.payload.list;
    },
    setLivingArrangements(state, action) {
      state.previousLivingArrangements =
        action.payload.previousLivingArrangements;
      state.currentLivingArrangements =
        action.payload.currentLivingArrangements;
    },
    setEditLivingArrangement(state) {
      state.isEditLivingArrangement = !state.isEditLivingArrangement;
    },
    setSelectedArrangement(state, action) {
      state.selectedArrangement = action.payload.selectedArrangement;
    },
    setPropertyType(state, action) {
      state.property_type = action.payload;
    },
    setPropertyReq(state, action) {
      state.propReq = action.payload;
    },
    setBathroomCount(state, action) {
      state.bathroom = action.payload;
    },
    setCarSpacesCount(state, action) {
      state.car_spaces = action.payload;
    },
    setBedRoomCount(state, action) {
      state.bed_room = action.payload;
    },
    setAvailability(state, action) {
      state.availability = action.payload;
    },
    setPriceRange(state, action) {
      state.greaterThen = action.payload[0];
      state.lessThen = action.payload[1];
    },
    resetFilter(state) {
      state.property_type = ["all"];
      state.bathroom = "all";
      state.car_spaces = "all";
      state.bed_room = "all";
      state.greaterThen = 50;
      state.lessThen = 5000;
      state.availability = "";
      state.filterReset= !state.filterReset
      // GetAllPropertiesForTenant();
    },
    updateDownStepCount(state, action) {
      state.downStepCount = action.payload.downStepCount;
    },
    updateSideStepCount(state, action) {
      state.sideStepCount = action.payload.sideStepCount;
    },
    updatePrefrenceStepCount(state, action) {
      state.prefrenceStepCount = action.payload.prefrenceStepCount;
    },
    setSerchPrefrence(state, action) {
      state.searchPrefrence = action.payload.searchPrefrence;
    },
    supportedDocs(state,action){
      state.supportedDocs = action.payload.supportedDocs
    },
    signOutTenant(state, action) {
      state.tenantProperty.agentName = null;
      state.tenantProperty.agentPhone = null;
      state.tenantProperty.relationship = null;
      state.tenantProperty.refreeName = null;
      state.tenantProperty.refreeEmail = null;
      state.tenantProperty.knownYears = null;
      state.tenantProperty.refreePhone = null;
      state.tenantProperty.user_id = null;
      state.currentLivingArrangements = [];

      state.previousLivingArrangements = [];
      state.isEditLivingArrangement = false;
      state.selectedArrangement = null;
      state.browsePropertyList = [];
      state.watchList = [];
      state.error = null;
      state.property_type = ["all"];
      state.bed_room = "all";
      state.availability = "";
      state.bathroom = "all";
      state.downStepCount = 0;
      state.sideStepCount = 0;
      state.prefrenceStepCount = 0;
      state.car_spaces = "";
      state.greaterThen = 0;
      state.lessThen = 10000;
      state.searchPrefrence = {};
      state.totalWathlist = 0;
    },
  },
});

export default slice.reducer;

export function LogoutTenant() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.signOutTenant());
  };
}
export function AddCurrentLandlordFunc(formData, navigate, setting = false) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/tenant/refree",
        {
          ...formData,
          setting,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async function (response) {
        await dispatch(
          slice.actions.tenantLandlord({
            agentName: response.data.agentName,
            agentPhone: response.data.agentPhone,
            refreeEmail: response.data.refreeEmail,
            relationship: response.data.relationship,
            refreeName: response.data.refreeName,
            knownYears: response.data.knownYears,
            refreePhone: response.data.refreePhone,
          })
        );
        await dispatch(getUser());
        if(window.location.pathname !== "/setting") {dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );}
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(updateDownStepCount({ downStepCount: 2 }));
      })
      .catch(function (err) {
        if(window.location.pathname !== "/setting") dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error && window.location.pathname !== "/setting") {
          navigate("/tenant/id-document");
        }
      });
  };
}
export function SendDocPandaDoc(id, files) {
  const formData = new FormData();
  for (let index = 0; index < files.length; index++) {
    const element = files[index];
    formData.append("file", element[0]);
    formData.append("userId", id);
  }

  return async (dispatch, getState) => {
    try {
      await axios.post("/tenant/panda_doc", formData, {
        headers: {
          "content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      dispatch(ShowSnackBar({ severity: "error", message: err.message }));
    }
  };
}
export function DetailsFunc(formValues, navigate) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/auth/edit-profile",
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${getState().auth.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        dispatch(slice.actions.tenantInfo({ user_id: response?.data.user_id }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(updateDownStepCount({ downStepCount: 1 }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate("/tenant/current-landlord");
          // window.location.href = "/tenant/current-landlord";
        }
      });
  };
}
export function AddDocument(
  driversLicence,
  passport,
  medicareCard,
  birthCertificate,
  userId,
  navigate,
  fullname,
  Date_of_Birth,
  email,
  address,
  suburb,
  postcode,
  state,
  DLNumber,
  DLCard,
  DLstate,
  PassportCountry,
  PassportNumber,
  MedicareCardNumber,
  Cardcolour,
  CardExpiryDate,
  ReferenceNumber,
  MedicalCardFullName
) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    const formData = new FormData();
    let point;
    if (driversLicence || passport || medicareCard || birthCertificate) {
      formData.append("driversLicence", driversLicence);
      formData.append("passport", passport);
      formData.append("medicareCard", medicareCard);
      formData.append("birthCertificate", birthCertificate);
      formData.append("id", userId);
      formData.append("fullname", fullname);
      formData.append("Date_of_Birth", Date_of_Birth);
      formData.append("email", email);
      formData.append("address", address);
      formData.append("suburb", suburb);
      formData.append("postcode", postcode);
      formData.append("state", state);
      formData.append("DLNumber", DLNumber);
      formData.append("DLCard", DLCard);
      formData.append("DLstate", DLstate);
      formData.append("PassportCountry", PassportCountry);
      formData.append("PassportNumber", PassportNumber);
      formData.append("MedicareCardNumber", MedicareCardNumber);
      formData.append("Cardcolour", Cardcolour);
      formData.append("CardExpiryDate", CardExpiryDate);
      formData.append("ReferenceNumber", ReferenceNumber);
      formData.append("MedicalCardFullName", MedicalCardFullName);
    }

    dispatch(slice.actions.tenantloader({ loader: true }));

    await axios
      .post("/tenant/add-document", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then(function (response) {
        point = response.data.point;
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(slice.actions.tenantloader({ loader: false }));
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(updateDownStepCount({ downStepCount: 3 }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(slice.actions.tenantloader({ loader: false }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error && point >= 100) {
          // navigate("/tenant/address-history");
          navigate("/tenant/bank-statement");
          //  window.location.href = "/tenant/address-history";
        } else {
          dispatch(
            ShowSnackBar({
              severity: "warning",
              message: "Please upload more document to continue",
            })
          );
        }
      });
  };
}
export function AddBankStatement(bankStatement, userId) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    const formData= {
      id: userId,
      file:bankStatement,
    }

    try {
      const response = await axios.post(
        "/tenant/tenantBankStatement",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      dispatch(
        ShowSnackBar({ severity: "success", message: response.data.message })
      );
      dispatch(updateIsLoading({ isLoading: false, error: false }));

      // Redirect if needed
      // if (!getState().auth.error && point >= 100) {
      //   window.location.href = "/tenant/connect-bank";
      // } else {
      //   dispatch(
      //     ShowSnackBar({
      //       severity: "warning",
      //       message: "Please upload more document to continue",
      //     })
      //   );
      // }
    } catch (error) {
      if(window.location.pathname !== "/setting") dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      dispatch(updateIsLoading({ isLoading: false, error: true }));
    }
  };
}

export function GetBankStatement(userId) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));

    try {
      const response = await axios.post("/tenant/tenantBankStatement", {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      dispatch(
        ShowSnackBar({ severity: "success", message: response.data.message })
      );
      dispatch(updateIsLoading({ isLoading: false, error: false }));

      // Redirect if needed
      // if (!getState().auth.error && point >= 100) {
      //   window.location.href = "/tenant/connect-bank";
      // } else {
      //   dispatch(
      //     ShowSnackBar({
      //       severity: "warning",
      //       message: "Please upload more document to continue",
      //     })
      //   );
      // }
    } catch (error) {
      dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      dispatch(updateIsLoading({ isLoading: false, error: true }));
    }
  };
}
export function AddNotification(listing, userId, mobileNumber, email) {
  return async (dispatch, getState) => {
    const formData = new FormData();
    formData.append("listing", listing);
    formData.append("id", userId);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);

    try {
      const response = await axios.post("/tenant/set_notification", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      dispatch(
        ShowSnackBar({ severity: "success", message: response.data.message })
      );
      dispatch(updateIsLoading({ isLoading: false, error: false }));
    } catch (error) {
      dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      dispatch(updateIsLoading({ isLoading: false, error: true }));
    }
  };
}
export function AddToWatchlist(data) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        `/tenant/add_to_watchlist`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
        dispatch();
      })
      .finally((response) => {
        if (!getState().auth.error) {
          // window.location.href = "/watchlist";
        }
      });
  };
}
export function GetAllPropertiesForTenant({propReq=[], search=""}) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    const {
      bed_room,
      bathroom,
      car_spaces,
      property_type,
      greaterThen,
      lessThen,
      availability,
    } = getState().tenant;

    const arrayOfTexts =
      propReq && propReq?.length > 0 ? propReq?.map((item) => item.text) : [];

    let url = `?property_type=${property_type}&bed_room=${bed_room}&bathroom=${bathroom}&car_spaces=${car_spaces}&lessThen=${lessThen}&greaterThen=${greaterThen}&availability=${availability}&search=${arrayOfTexts}`;
    // let search = data?.data.search;
    // if (search) {
    //   url = url + `&search=${search}`;
    // }

    await axios
      .get(`/tenant/get_all_property/${url}&srch=${search}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.allPropertyListTenant({
            list: response.data.filteredResults,
          })
        );
        // dispatch(
        //   ShowSnackBar({ severity: "success", message: response.data.message })
        // );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        // dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}

export function GetAllPropertiesForTenantWithoutLogin({propReq=[], search=""}) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    const {
      bed_room,
      bathroom,
      car_spaces,
      property_type,
      greaterThen,
      lessThen,
      availability,
    } = getState().tenant;

    const arrayOfTexts =
      propReq && propReq.length > 0 ? propReq.map((item) => item.text) : [];

    let url = `?property_type=${property_type}&bed_room=${bed_room}&bathroom=${bathroom}&car_spaces=${car_spaces}&lessThen=${lessThen}&greaterThen=${greaterThen}&availability=${availability}&search=${arrayOfTexts}`;

    await axios
      .get(`/tenant/get_properties/${url}&srch=${search}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.allPropertyListTenant({
            list: response.data.filteredResults,
          })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}

export function GetWatchlist({ pgNum = 1, pgSize = 10 }) {
  return async (dispatch, getState) => {
    await axios
      .get(`/tenant/get_watchlist?pgNum=${pgNum}&pgSize=${pgSize}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.getWatchlist({ list: response.data.watchlist }));
        dispatch(
          allPropertyList({
            pageNumber: response.data.pageNumber,
            pageSize: response.data.pageSize,
            totalProperties: response.data.totalProperties,
            noOfPropertiesPerPage: response.data.noOfPropertiesPerPage,
          })
        );
        // dispatch(
        //   ShowSnackBar({ severity: "success", message: response.data.message })
        // );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        // dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}
export function GetPrefrence(id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/tenant/get_prefrence/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(
          slice.actions.setSerchPrefrence({
            searchPrefrence: response.data.searchPrefrence,
          })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        if(window.location.pathname !== "/setting") dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}
export function AddLivingArrangement(data, livArrange) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        `/tenant/add_living_arrangement`,
        {
          ...data,
          livArrange,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        //
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
    // .finally(() => {
    //   if (!getState().auth.error) {
    //     window.location.href = "/watchlist";
    //   }
    // });
  };
}
export function sendRefreeEmail(
  refEmail,
  refName,
  agentName,
  addRefEmail,
  addRefName,
  senderName
) {
  return async (dispatch, getState) => {
    await axios
      .post(
        `/tenant/sendEmailToRefree`,
        { refEmail, refName, agentName, addRefEmail, addRefName, senderName },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}
export function AddSearchPreference(data, navigate) {
  console.log(data, "--------");
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        `/tenant/add_search_preference`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        //
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(updatePrefrenceStepCount({ prefrenceStepCount: 1 }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        console.log(window.location.pathname, "PPATH");
        if (!getState().auth.error && window.location.pathname !== "/setting") {
          navigate("/tenant/rental-status");
        }
      });
  };
}
export function EditLivingArrangement(data) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        `/tenant/edit_living_arrangement`,
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        //
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}
export function GetSelectedArrangement(arrangementId) {
  return async (dispatch, getState) => {
    await axios
      .get(`/tenant/get_selected_arrangement/${arrangementId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        dispatch(slice.actions.setSelectedArrangement(response.data));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}
export function GetLivingArrangements() {
  return async (dispatch, getState) => {
    await axios
      .get("/tenant/get_living_arrangements", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.setLivingArrangements(response.data));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}
export function AddRentalStatus(data, navigate) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/tenant/rental_status",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(updateSideStepCount({ sideStepCount: 2 }));
        dispatch(updatePrefrenceStepCount({ prefrenceStepCount: 2 }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate("/tenant/notification-setting");
        }
      });
  };
}

export function SignatureUpload(data, role, id, navigate) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/application/signature",
        {
          data,
          role,
          id,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          // navigate(`/Send_contractReview?id=${id}`);
          // window.location.href ="/applications"
        }
      });
  };
}

export function AddSupportedDocs(userId, extraInfo, files) {
  const formData = {
    userId,
    extraInfo,
    file: files,
    // extraInfo
  };

  return async (dispatch, getState) => {

    await axios
      .post("/tenant/add-supported-docs", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then(function (response) {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
      })
      .catch((error) => {
        if(window.location.pathname !== "/setting") dispatch(ShowSnackBar({ severity: "error", message: error.message }));
      });
  };
}

export function GetSupportedDocs(id){
  return async (dispatch, getState)=> {
    await axios.get(`/tenant/get_supported_docs/${id}`,{
      headers:{
        "Content-Type": "application/json",
      }
    })
    .then((response) => {
      dispatch(slice.actions.supportedDocs({ supportedDocs: response.data }));
    })
    .catch((err) => {
      console.log(err);
    });
  }
}

export const {
  setEditLivingArrangement,
  setPropertyType,
  setPropertyReq,
  setBathroomCount,
  setCarSpacesCount,
  updateSideStepCount,
  updateDownStepCount,
  updatePrefrenceStepCount,
  setBedRoomCount,
  setAvailability,
  resetFilter,
  setPriceRange,
} = slice.actions;
