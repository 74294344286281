import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { ShowSnackBar, allPropertyList } from "./app";
import { getBusinessDetails, updateIsLoading } from "../slices/auth";

const initialState = {
  listing: {
    listing_id: null,
    property_id: null,
    property: null,
    landlordInfo_id: null,
    landlordInfo: null,
    inspection_id: null,
    inspection: null,
  },
  listingList: [],
  listingDetails: null,
  approvedUsers: [],
  matchedTenants: [],
  sharePropLoader: true,
  attended:[]
};

const slice = createSlice({
  name: "listing",
  initialState,
  reducers: {
    selectProperty(state, action) {
      state.listing.property_id = action.payload.property_id;
    },
    addLandlordInfo(state, action) {
      if (action.payload.landlordInfo_id) {
        state.listing.landlordInfo_id = action.payload.landlordInfo_id;
        state.listing.listing_id = action.payload.listing_id;
      } else {
        state.listing.landlordInfo = action.payload.info;
      }
    },
    addInspection(state, action) {
      if (action.payload.inspection_id) {
        state.listing.inspection_id = action.payload.inspection_id;
      } else {
        state.listing.inspection = action.payload.info;
      }
    },
    listingList(state, action) {
      state.listingList = action.payload.list;
    },
    allattended(state,action){
      state.attended = action.payload.attended;
    },
    getListingDetails(state, action) {
      state.listingDetails = action.payload.listingDoc;
    },
    setMatchedTenants(state, action) {
      state.matchedTenants = action.payload.matchedTenants;
    },
    approvedUsersList(state, action) {
      state.approvedUsers = action.payload.approvedUsers;
    },
    emptyListing(state, action) {
      state.listing.listing_id = null;
      state.listing.property_id = null;
      state.listing.property = null;
      state.listing.landlordInfo_id = null;
      state.listing.landlordInfo = null;
      state.listing.inspection_id = null;
      state.listing.inspection = null;
    },
    setSharePropLoader(state, action){
      state.sharePropLoader = action.payload.sharePropLoader
    },
    signOutListing(state, action) {
      state.listing.listing_id = null;
      state.listing.property_id = null;
      state.listing.property = null;
      state.listing.landlordInfo_id = null;
      state.listing.landlordInfo = null;
      state.listing.inspection_id = null;
      state.listing.inspection = null;
      state.listingList = [];
      state.listingDetails = null;
      state.approvedUsers = [];
      state.sharePropLoader=true
    },
  },
});

export default slice.reducer;

export function LogoutListing() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.signOutListing());
  };
}
export function EmptyListing() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.emptyListing());
  };
}
export function SelectPropertyFunc(property_id) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.selectProperty({ property_id: property_id }));
  };
}
// export function GetApprovedUsers(price) {
//   return async (dispatch, getState) => {
//     dispatch(updateIsLoading({ isLoading: true, error: false }));

//     await axios
//       .get(`/listing/approved-users?price=${price?.price}&pgNum=${price?.pgNum}&pgSize=${price?.pgSize}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${getState().auth.token}`,
//         },
//       })
//       .then((response) => {
//         dispatch(slice.actions.approvedUsersList({ approvedUsers: response.data.users }));
//         dispatch(
//           allPropertyList({
//             pageNumber: response.data.pageNumber,
//             pageSize: response.data.pageSize,
//             totalProperties: response.data.totalProperties,
//             noOfPropertiesPerPage: response.data.noOfPropertiesPerPage,
//           })
//         );
//         dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
//         dispatch(updateIsLoading({ isLoading: false, error: false }));
//       })
//       .catch((err) => {
//         dispatch(updateIsLoading({ isLoading: false, error: true }));
//         dispatch(ShowSnackBar({ severity: "error", message: err.message }));
//       });
//   };
// }

export function GetApprovedUsers({ price, pgNum, pgSize, searchQuery }) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));

    try {
      const response = await axios.get(
        `/listing/approved-users?price=${price?.price}&pgNum=${pgNum}&pgSize=${pgSize}&searchQuery=${searchQuery}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      );

      dispatch(slice.actions.approvedUsersList({ approvedUsers: response.data.users }));
      dispatch(
        allPropertyList({
          pageNumber: response.data.pageNumber,
          pageSize: response.data.pageSize,
          totalProperties: response.data.totalProperties,
          noOfPropertiesPerPage: response.data.noOfPropertiesPerPage,
        })
      );
      // dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
      dispatch(updateIsLoading({ isLoading: false, error: false }));
    } catch (err) {
      dispatch(updateIsLoading({ isLoading: false, error: true }));
      dispatch(ShowSnackBar({ severity: "error", message: err.message }));
    }
  };
}

export function ActivateDeactivateListing(id) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        `/listing/activate-deactivate-listing`,
        {
          id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(GetAllListings({ pgNum: 1, pgSize: 10 }));
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(updateIsLoading({ isLoading: false, error: true }));
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function AddLandLordInfoFunc(formValues, navigate) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));
    await axios
      .post(
        "/listing/add-landlord-info",
        {
          ...formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {
        dispatch(
          slice.actions.addLandlordInfo({
            landlordInfo_id: response.data.landlordInfo_id,
            listing_id: response.data.listing_id,
          })
        );

        // dispatch(AddAddress({ address_id: response.data.id }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          navigate('/add_inspections')
          // window.location.href = "/add_inspections";
        }
      });
  };
}
export function AddInspectionsFunc(formValues, handleCloseDialog, location, data) {
  return async (dispatch, getState) => {
    dispatch(updateIsLoading({ isLoading: true, error: false }));

    let insId 
    if(location == '/add_inspections'){
      insId = getState().listing?.listing?.inspection_id
    }else{
      insId = getState().listing?.listingDetails?.inspection_id
    }

    await axios
      .post(
        `/listing/add-inspections?listing_id=${getState().listing.listing && getState().listing?.listing?.listing_id ? getState().listing?.listing?.listing_id : getState().listing?.listingDetails?.listing_id
        }&property_id=${getState().listing?.listing?.property_id}&inspection_id=${insId}&isEdit=${data && Object.keys(data).length > 0 ? true : false}&index=${data && data?.index}`,
        {
          formValues,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then(function (response) {

        if (response.data.message === 'Inspection Added' && location !== '/add_inspections') {
          handleCloseDialog()
        }
        if (data && Object.keys(data).length > 0) {
          handleCloseDialog()
        }
        if (response.data.message === 'Inspection Added' && location == '/add_inspections') {
          dispatch(slice.actions.addInspection({ inspection_id: response.data.inspection_id }));
        }


        dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch(function (err) {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          if (location === '/add_inspections') {
            window.location.href = "/review_listing";
          }

        }
      });
  };
}
export function deleteInspection(index, handleOpenDialog) {
  return async (dispatch, getState) => {
    await axios
      .delete(`/listing/deleteInspection?inspection_id=${getState().listing.listing&&getState().listing.listing.inspection_id ? getState().listing.listing.inspection_id : getState().listing?.listingDetails?.inspection_id}&index=${index}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        // dispatch(slice.actions.listingList({ list: [] }));
        dispatch(ShowSnackBar({ severity: "success", message: response.data.message }));
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}
export function GetLandlordInfo(id) {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/listing/get_landlord_info/${id}`,

        {
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.addaddress({ address: response.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function GetInspection(id) {
  return async (dispatch, getState) => {
    await axios
      .get(`/listing/get_inspections/${id}`, {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        dispatch(slice.actions.addInspection({ info: response.data }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function CreateListing(data) {
  return async (dispatch, getState) => {
    await axios
      .post(
        "/listing/create",
        {
          ...data,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.emptyListing())
        
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
        dispatch(getBusinessDetails(data?.user_id))


      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      })
      .finally(() => {
        if (!getState().auth.error) {
          data.navigate('/listings')
          // window.location.href = "/listings";
        }
      });
  };
}
export function GetAllListings({ pgNum = 1, pgSize = 10,searchQuery }) {
  return async (dispatch, getState) => {
    await axios
      .get(`/listing/get_all?pgNum=${pgNum}&pgSize=${pgSize}&searchQuery=${searchQuery?searchQuery:""}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        const allListingData=response.data.allListings
        dispatch(
          slice.actions.listingList({ list: allListingData })
        );
        dispatch(
          allPropertyList({
            // list: response.data.allListings,
            pageNumber: response.data.pageNumber,
            pageSize: response.data.pageSize,
            totalProperties: response.data.totalProperties,
            noOfPropertiesPerPage: response.data.noOfPropertiesPerPage,
          })
        );
        dispatch(updateIsLoading({ isLoading: false, error: false }));
      })
      .catch((err) => {
        dispatch(updateIsLoading({ isLoading: false, error: true }));
      });
  };
}
export function getListing(id,user_id="") {
  return async (dispatch, getState) => {

    await axios
      .get(
        `/listing/get_listing/?listing_id=${id}&uId=${user_id}&business_id=${getState().auth.business_id
        }`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        dispatch(slice.actions.getListingDetails({listingDoc: response.data.listingDoc}));
        dispatch(slice.actions.setSharePropLoader({sharePropLoader: false}))

      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function getMatchedTenants(id) {
  return async (dispatch, getState) => {
    await axios
      .get(
        `/listing/get_matched_tenants/?listing_id=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getState().auth.token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data, "!!!!!!!!!!!!!!!!!!!")
        dispatch(
          slice.actions.setMatchedTenants({
            matchedTenants: response.data.result,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function deleteListingFunc(id) {
  return async (dispatch, getState) => {
    await axios
      .delete(`/listing/delete/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        // dispatch(slice.actions.getProperty({ propertyData: response.data.property }));
        dispatch(slice.actions.listingList({ list: [] }));
        dispatch(
          ShowSnackBar({ severity: "success", message: response.data.message })
        );
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}

export function addOrRemoveAttended(_id,info_id){
  return async (dispatch, getState) => {
    await axios
      .post(`/listing/addOrRemoveAttended/?tenant_id=${_id}&info_id=${info_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      })
      .then((response) => {
        // dispatch(slice.actions.getProperty({ propertyData: response.data.property }));
        dispatch(slice.actions.allattended({ attended: response.data.inspections[0].attended}))
      })
      .catch((err) => {
        dispatch(ShowSnackBar({ severity: "error", message: err.message }));
      });
  };
}

export function getAttended(info_id){
return async(dispatch,getState)=>{
  await axios.get(`/listing/addOrRemoveAttended/?info_id=${info_id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getState().auth.token}`,
    },
  }).then((response) => {
    dispatch(slice.actions.allattended({ attended: response.data.inspections[0].attended}));
  })
  .catch((err) => {
    dispatch(ShowSnackBar({ severity: "error", message: err.message }));
  });
}
}

export const { listingList, getListingDetails,setSharePropLoader } = slice.actions;
